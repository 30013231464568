<template>
    <v-col class="px-12 py-9" cols="12">
        <h1 class="text-h1">{{ $t('prototype.heading') }}</h1>
        <moodboard-list :moodboard="prototype" :user="user" :item-heading="$t('moodboard.label_prototype')">
            <template #actions="{moodboard, user}">
                <v-container class="pa-0 pt-6" v-if="$inGroupDesigners()">
                    <v-row>
                        <v-col cols="12" class="pa-0 ml-3">
                            <workflow-design-unapprove-dialog v-slot="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" color="primary" class="mr-8 mb-6 boxed-icon" outlined tile elevation="0">
                                    {{ $t('workflow_prototype.back_to_design') }}
                                    <v-icon right>mdi-arrow-left</v-icon>
                                </v-btn>
                            </workflow-design-unapprove-dialog>

                            <v-btn v-if="$isStepReleased(PROJECT_PROTOTYPE)" color="primary" class="mr-8 mb-6 boxed-icon" outlined tile elevation="0" @click="blockForArchitect">
                                {{ $t('workflow_prototype.revoke_from_architect') }}
                                <v-icon right>mdi-cancel</v-icon>
                            </v-btn>
                            <v-btn v-else color="primary" class="mr-8 mb-6 boxed-icon" tile elevation="0" @click="releaseToArchitect">
                                {{ $t('workflow_prototype.release_to_architect') }}
                                <v-icon right>mdi-arrow-right</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="pa-0 ml-3">
                            <moodboard-add-files-entry-dialog :moodboard="moodboard" :user="user" v-slot="{ attrs, on }">
                                <v-btn v-bind="attrs" v-on="on" color="primary" class="mr-8 mb-6 boxed-icon" tile elevation="0" :disabled="!moodboard.$ref">
                                    {{ $t('moodboard.add_files') }}
                                    <v-icon right>mdi-file-plus-outline</v-icon>
                                </v-btn>
                            </moodboard-add-files-entry-dialog>

                            <design-add-dialog v-slot="{ on, attrs }" :project="project" :user="user" @added="transferDesignToProto">
                                <v-btn v-bind="attrs" v-on="on" color="primary" class="mr-8 mb-6 boxed-icon" tiles elevation="0" :disabled="!project.$ref">
                                    {{ $t('workflow_prototype.add_3d_scan') }}
                                    <v-icon right>mdi-rotate-3d</v-icon>
                                </v-btn>
                            </design-add-dialog>
                        </v-col>
                    </v-row>
                </v-container>
                <v-container class="pa-0 pt-6" v-if="$inGroupArchitects()">
                    <v-row>
                        <v-col cols="12" class="pa-0 ml-3">
                            <v-btn v-if="!$isStepApproved(PROJECT_PROTOTYPE)" :disabled="!$isStepReleased(PROJECT_PROTOTYPE)" class="mt-3 boxed-icon" color="primary" tile @click="approveStep">
                                {{ $t('workflow_prototype.approve') }}
                                <v-icon right>mdi-check</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
        </moodboard-list>
    </v-col>
</template>

<script>
import ThePreviewer from '@/components/ThePreviewer';
import MoodboardList from '@/components/MoodboardList';
import { mapGetters, mapState } from 'vuex';
import MoodboardAddFilesEntryDialog from '@/components/dialogs/MoodboardAddFilesEntryDialog';
import DesignAddDialog from '@/components/dialogs/DesignAddDialog';
import { updateFirestoreDesign } from '@/firebase/firestore/projects/designs';
import { uploadFilesAndAddFirestoreMoodboardEntry } from '@/firebase/firestore/projects/moodboards';
import WorkflowDesignUnapproveDialog from '@/components/dialogs/WorkflowDesignUnapproveDialog';

export default {
    name: 'TheProjectPrototype',

    components: {WorkflowDesignUnapproveDialog, DesignAddDialog, MoodboardAddFilesEntryDialog, MoodboardList, ThePreviewer},

    computed: {
        ...mapGetters('authentication', {
            user: 'getUser',
        }),
        ...mapGetters('project', {
            prototype: 'proto',
        }),
        ...mapState('project', {
            project: 'boundInstance',
        }),
    },

    methods: {
        async transferDesignToProto (payload) {
            const refDesign = payload[0];
            await updateFirestoreDesign(refDesign, {visible: false, deletable: false});
            await uploadFilesAndAddFirestoreMoodboardEntry(this.prototype.$ref, {
                heading: this.$t('moodboard.label_prototype_scan'),
                design: refDesign,
                user: this.user,
            });
        },

        approveStep () {
            return this.$approveStep(this.PROJECT_PROTOTYPE)
                .finally(() => this.$store.dispatch('workflow/gotoStep', this.PROJECT_PRODUCTION));
        },

        releaseToArchitect () {
            return this.$releaseStep(this.PROJECT_PROTOTYPE)
                .finally(() => this.$store.dispatch('statusMessage/success', this.$t('workflow_prototype.released_to_architect')));
        },

        blockForArchitect () {
            return this.$blockStep(this.PROJECT_PROTOTYPE)
                .finally(() => this.$store.dispatch('statusMessage/success', this.$t('workflow_prototype.revoked_from_architect')));
        },
    }
};
</script>

<style scoped>

</style>
