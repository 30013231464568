var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"px-12 py-9",attrs:{"cols":"12"}},[_c('h1',{staticClass:"text-h1"},[_vm._v(_vm._s(_vm.$t('prototype.heading')))]),_c('moodboard-list',{attrs:{"moodboard":_vm.prototype,"user":_vm.user,"item-heading":_vm.$t('moodboard.label_prototype')},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var moodboard = ref.moodboard;
var user = ref.user;
return [(_vm.$inGroupDesigners())?_c('v-container',{staticClass:"pa-0 pt-6"},[_c('v-row',[_c('v-col',{staticClass:"pa-0 ml-3",attrs:{"cols":"12"}},[_c('workflow-design-unapprove-dialog',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-8 mb-6 boxed-icon",attrs:{"color":"primary","outlined":"","tile":"","elevation":"0"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('workflow_prototype.back_to_design'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-arrow-left")])],1)]}}],null,true)}),(_vm.$isStepReleased(_vm.PROJECT_PROTOTYPE))?_c('v-btn',{staticClass:"mr-8 mb-6 boxed-icon",attrs:{"color":"primary","outlined":"","tile":"","elevation":"0"},on:{"click":_vm.blockForArchitect}},[_vm._v(" "+_vm._s(_vm.$t('workflow_prototype.revoke_from_architect'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-cancel")])],1):_c('v-btn',{staticClass:"mr-8 mb-6 boxed-icon",attrs:{"color":"primary","tile":"","elevation":"0"},on:{"click":_vm.releaseToArchitect}},[_vm._v(" "+_vm._s(_vm.$t('workflow_prototype.release_to_architect'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pa-0 ml-3",attrs:{"cols":"12"}},[_c('moodboard-add-files-entry-dialog',{attrs:{"moodboard":moodboard,"user":user},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-8 mb-6 boxed-icon",attrs:{"color":"primary","tile":"","elevation":"0","disabled":!moodboard.$ref}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('moodboard.add_files'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-file-plus-outline")])],1)]}}],null,true)}),_c('design-add-dialog',{attrs:{"project":_vm.project,"user":user},on:{"added":_vm.transferDesignToProto},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-8 mb-6 boxed-icon",attrs:{"color":"primary","tiles":"","elevation":"0","disabled":!_vm.project.$ref}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('workflow_prototype.add_3d_scan'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-rotate-3d")])],1)]}}],null,true)})],1)],1)],1):_vm._e(),(_vm.$inGroupArchitects())?_c('v-container',{staticClass:"pa-0 pt-6"},[_c('v-row',[_c('v-col',{staticClass:"pa-0 ml-3",attrs:{"cols":"12"}},[(!_vm.$isStepApproved(_vm.PROJECT_PROTOTYPE))?_c('v-btn',{staticClass:"mt-3 boxed-icon",attrs:{"disabled":!_vm.$isStepReleased(_vm.PROJECT_PROTOTYPE),"color":"primary","tile":""},on:{"click":_vm.approveStep}},[_vm._v(" "+_vm._s(_vm.$t('workflow_prototype.approve'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-check")])],1):_vm._e()],1)],1)],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }